import React, { useEffect, useState, useRef, useCallback, createContext, useContext } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption
} from '@reach/combobox';
import axios from 'axios';
import haversine from 'haversine-distance';
import iconLocation from './img/location-marker.png';
import mapStyles from './mapStyles';

let gkey = 'AIzaSyDEO81MsfJ8j6eatcujDO8TzIQBmprqEX0';

const libraries = ['places'];
const mapContainerStyle = {
    width: '100%',
    height: '100%',
    border: '2px solid #ccc'
};

const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true
};

const ValueContext = createContext({});

function App() {
    const [manheimLocations, setManheimLocations] = useState([]);
    const [staticLocations, setStaticLocations] = useState([]);
    const [markers, setMarkers] = useState([]); // Initialize as empty array
    const [selected, setSelected] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [searchLat, setSearchLat] = useState(0);
    const [searchLong, setSearchLong] = useState(0);
    const [distance, setDistance] = useState(0);
    const [locationMessage, setLocationMessage] = useState('loaded');
    const [center, setCenter] = useState({
        lng: -84.484319,
        lat: 34.0581564
    });
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: gkey,
        libraries
    });

    const mapRef = useRef(null); // Initialize ref

    const panTo = useCallback(({ lat, lng }) => {
        if (mapRef.current) {
            mapRef.current.panTo({ lat, lng });
            mapRef.current.setZoom(8);
        } else {
            console.warn('mapRef.current is not defined yet.');
        }
    }, []);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    // Fetch locations and initialize markers
    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const res = await axios.get('https://elementatmanheim.com/wp-json/atd/v1/location');
                setMarkers(res.data);
                setStaticLocations(res.data);
                setManheimLocations(res.data);
            } catch (err) {
                console.error('Failed to fetch locations:', err);
            }
        };

        fetchLocations();
    }, []);

    // Get user location and sort locations by distance
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ lat: latitude, lng: longitude });
                panTo({ lat: latitude, lng: longitude });

                const sortedLocations = staticLocations
                    .map((location) => ({
                        ...location,
                        distance: Math.round(
                            haversine(
                                { lat: parseFloat(location.map.lat), lng: parseFloat(location.map.lng) },
                                { lat: latitude, lng: longitude }
                            ) * 0.000621
                        )
                    }))
                    .sort((a, b) => a.distance - b.distance);

                setManheimLocations(sortedLocations);
            },
            () => console.warn('User location not available.'),
            options
        );
    }, [staticLocations, panTo]);

    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading map';

    return (
        <ValueContext.Provider
            value={{
                manheimLocations,
                markers,
                setMarkers,
                selected,
                setSelected,
                userLocation,
                setUserLocation,
                searchLat,
                setSearchLat,
                searchLong,
                setSearchLong,
                distance,
                setDistance,
                center,
                setCenter,
                panTo,
                locationMessage,
                onMapLoad
            }}
        >
            <Page />
        </ValueContext.Provider>
    );
}

const Search = () => {
    const { panTo, searchLat, searchLong, setSearchLat, setSearchLong, setSelected } =
        useContext(ValueContext);
    const { ready, value, suggestions: { status, data }, setValue } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 33.748997, lng: () => -84.387985 },
            radius: 200 * 1000
        }
    });

    return (
        <Combobox
            onSelect={async (address) => {
                try {
                    const results = await getGeocode({ address });
                    const { lat, lng } = await getLatLng(results[0]);
                    panTo({ lat, lng });
                    setSearchLat(lat);
                    setSearchLong(lng);
                    setSelected(null);
                } catch (err) {
                    console.error(err);
                }
            }}
        >
            <ComboboxInput
                className="search-input"
                placeholder="Enter an address, city, or zip code"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
            />
            <ComboboxPopover>
                <ComboboxList>
                    {status === 'OK' &&
                        data.map(({ id, description }) => (
                            <ComboboxOption key={id} value={description} />
                        ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    );
};

const Page = ({ onMapClick, onLocationsPrint }) => {
    const stateVals = useContext(ValueContext);
    const {
        markers,
        manheimLocations,
        onMapLoad,
        setCenter,
        selected,
        setSelected,
        center,
        locationMessage
    } = stateVals;

    return (
        <div className="page-section page-section-white">
            <div className="container">
                <div className="flex-row flex-wrap" style={{ marginBottom: '0' }}>
                    <div className="flex-col flex-col-9">
                        <h2 className="h1 is-brand-blue location-finder-heading">Find a Sales Location</h2>
                    </div>
                    <div className="flex-col flex-col-3">
                        <form className="search-contain">
                            <Search className="search-input" />
                        </form>
                    </div>
                </div>

                <div className="flex-row flex-wrap">
                    <div className="flex-col flex-col-9 location-finder-map-container">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={6}
                            center={center}
                            options={options}
                            onLoad={onMapLoad}
                            onDragStart={() => setSelected(null)}
                            onClick={() => onMapClick(manheimLocations)}
                        >
                            {markers &&
                                markers.map((marker) => (
                                    <Marker
                                        key={marker.id} // Use a unique ID instead of Math.random()
                                        position={{
                                            lat: parseFloat(marker.map.lat),
                                            lng: parseFloat(marker.map.lng)
                                        }}
                                        icon={iconLocation}
                                        onClick={() => {
                                            setSelected(marker);
                                            setCenter({
                                                lat: parseFloat(marker.map.lat),
                                                lng: parseFloat(marker.map.lng)
                                            });
                                        }}
                                        title={marker.name}
                                    />
                                ))}
                            {selected && (
                                <InfoWindow
                                    position={{
                                        lat: parseFloat(selected.map.lat),
                                        lng: parseFloat(selected.map.lng)
                                    }}
                                    onCloseClick={() => setSelected(null)}
                                >
                                    <div style={{ marginBottom: '20px' }}>
                                        <a
                                            href={`https://site.manheim.com/en/locations/us-locations/${selected.slug}.html`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            data-location="Location Finder"
                                        >
                                            <h5 className="is-brand-green">{selected.name}</h5>
                                        </a>
                                        <a
                                            href={`tel:${selected.phone}`}
                                            className="is-brand-blue block m-y-md"
                                        >
                                            {selected.phone}
                                        </a>
                                        <div>
                                            <b>{selected.address.replace(/&nbsp;|&amp;nbsp;/g, '\u00A0')}</b>
                                        </div>
                                        <div>
                                            <b>{selected.location_address}</b>
                                        </div>
                                        <div>
                                            <span className="location-description">
                                                {selected.location_description}
                                            </span>
                                        </div>
                                    </div>
                                </InfoWindow>
                            )}
                        </GoogleMap>
                    </div>
                    <div className="flex-col flex-col-3 locations-to-load">
                        {locationMessage === 'loaded' ? (
                            <div className="location">
                                {manheimLocations.map((location, index) => {
                                    if (index < 5 && location !== undefined)
                                        return (
                                            <div
                                                key={location.id} // Use unique ID
                                                className="inner-location"
                                                onClick={() => {
                                                    stateVals.setSelected(location);
                                                    stateVals.panTo({
                                                        lat: parseFloat(location.map.lat),
                                                        lng: parseFloat(location.map.lng)
                                                    });
                                                }}
                                            >
                                                <div className="location-icon">
                                                    <img
                                                        src={iconLocation}
                                                        alt=""
                                                        style={{
                                                            minWidth: '24px',
                                                            maxWidth: '24px',
                                                            marginTop: '3px'
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="location-address"
                                                    key={location.id}
                                                >
                                                    <p>
                                                        <span className="is-brand-green">
                                                            <b>{location.name}</b>
                                                        </span>
                                                        <br />
                                                        <span className="location-street">
                                                            {location.address.replace(
                                                                /&nbsp;|&amp;nbsp;/g,
                                                                '\u00A0'
                                                            )}
                                                        </span>
                                                        <br />
                                                        <span className="location-description">
                                                            {location.location_description}
                                                        </span>
                                                        <span className="location-distance">
                                                            {location.distance
                                                                ? `${location.distance} miles away`
                                                                : ''}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                })}
                            </div>
                        ) : (
                            <div className="location-loading">Loading locations...</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default App;
